import '../scss/stream_player.scss';

import { plugin_registry, PluginBase } from "nk-plugin-registry";


@plugin_registry.register("StreamPlayer")
class StreamPlayer extends PluginBase {
    constructor($node) {
        super($node);
        this.$playButton = $node.querySelector('#play-button');
        this.$player = $node.querySelector("#player");
        this.$originalSourceUrl = this.$player.getAttribute("src");

    }

    loaded($node) {
        super.loaded($node);

        this._install_event_listeners();
    }

    _install_event_listeners = () => {
        this.$playButton.addEventListener("click", this._play_button_click);
    }

    _play_button_click = () => {

        const classList = this.$playButton.children[0].classList;

        if (classList.contains("icon-play-circle")) {
            this.$player.setAttribute("src", this.$originalSourceUrl);
            this.$player.play();
            classList.replace("icon-play-circle", "icon-pause-circle");
            classList.add("orange");
            console.log(1, classList)
        } else {
            this.$player.pause()
            this.$player.setAttribute("src", "");
            classList.replace("icon-pause-circle", "icon-play-circle");
            classList.remove("orange");

        }
    }
}